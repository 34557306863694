import {
  Link,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  TabsProps
} from '@chakra-ui/react';
import ScrollWithoutScrollBar from '@/components/GamesCatalog/ScrollWithoutScrollBar';
import { SerializedStyles } from '@emotion/react';
import {
  MouseEvent,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { ArrowDown } from '@/theme/Icons';
import { useMenuRouteMatch } from '@/hooks/useRouteMatch';
import { useHash } from '@/hooks/useHash';

export function ExpendableMenu<TItem extends Item>({
  items,
  options,
  initialOpenTabIndex = undefined,
  initialHighlightedTabIndex = undefined,
  ...tabProps
}: ExpendableMenuProps<TItem>) {
  const tabPanelsRef = useRef<HTMLDivElement | null>(null);
  const { setHash } = useHash();

  // Highlighting menu parent item based on routeMath
  const links = useMemo(() => getLinksFromItems(items), [items]);
  const { routeMatch, locale } = useMenuRouteMatch(links);

  const [highlightedIndex, setHighlightedIndex] = useState<number | undefined>(
    routeMatch?.index ?? initialHighlightedTabIndex
  );
  useEffect(() => {
    if (routeMatch) {
      setHighlightedIndex(routeMatch?.index);
    } else if (initialHighlightedTabIndex !== undefined) {
      setHighlightedIndex(initialHighlightedTabIndex);
    }
  }, [routeMatch, initialHighlightedTabIndex]);

  // Menu
  const showMenu = items?.length > 0;
  const hasSubMenu = (index: number) =>
    (items?.[index]?.children?.length ?? 0) > 0;
  const [openTabIndex, setOpenTabIndex] = useState<number | undefined>(
    initialOpenTabIndex ?? 0
  );
  const [previousOpenTabIndex, setPreviousOpenTabIndex] = useState<
    number | undefined
  >(undefined);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState<boolean>(
    initialOpenTabIndex !== undefined && hasSubMenu(initialOpenTabIndex)
  );
  const showSubMenu =
    openTabIndex !== undefined &&
    items?.[openTabIndex]?.children?.length &&
    isSubMenuOpen;

  const getTabOptionalStyles = (item: TItem) => {
    return item?.optionName && options?.[item.optionName]?.styles
      ? options[item.optionName].styles
      : null;
  };
  const displayArrow = (item: TItem, index: number) => {
    return item?.children?.length ? (
      <ArrowDown
        width={'24px'}
        height={'24px'}
        transform={
          isSubMenuOpen && index === openTabIndex
            ? 'rotate(180deg)'
            : 'rotate(0deg)'
        }
      />
    ) : null;
  };
  const displayIcon = (item: TItem, index: number) => {
    return item?.optionName && options && options[item.optionName]?.icon
      ? options[item.optionName].icon
      : null;
  };
  const renderSubMenu = () => {
    if (!showSubMenu) return null;

    return (
      <TabPanels
        data-testid={'expandable-menu-tab-panels'}
        ref={tabPanelsRef}
        pt={3}
        pb={[3, 3, 4]}
        px={[7, 7, 10]}
        borderTop="1px solid"
        borderTopColor="whiteAlpha.100"
        maxWidth="100%"
        position={'absolute'}
        zIndex={1}
        background={'backgroundPrimaryDarker'}
        borderRadius={[0, 0, '0 0 15px 15px']}
      >
        {items?.map((item, index) => (
          <TabPanel
            data-testid={'expandable-menu-tab-panel'}
            key={`child-${index}`}
            display={'flex'}
            flexDirection={['column', 'column', 'row']}
            justifyContent={'start'}
            alignItems={'start'}
            gap={3}
            p={0}
            w={'100%'}
            maxHeight={'calc(100vh - var(--tab-panels-top))'}
            css={{
              '@media (min-width: 768px)': {
                msOverflowStyle: 'none',
                scrollbarWidth: 'none',
                '&::-webkit-scrollbar': {
                  display: 'none'
                }
              }
            }}
            overflowX={['hidden', 'hidden', 'auto']}
            overflowY={['auto', 'auto', 'hidden']}
          >
            {item?.children?.map((subItem, index) => (
              <Link
                data-testid={'expandable-menu-tab-panel-link'}
                flexGrow={0}
                flexShrink={0}
                key={index}
                display="inline-flex"
                padding={'0.6rem 1.3rem'}
                minWidth={['5.625rem']}
                height="2.625rem"
                alignItems={'center'}
                justifyContent={'center'}
                borderWidth="1px"
                borderStyle="solid"
                borderColor="whiteAlpha.200"
                borderRadius="0.625rem"
                fontSize="0.9375rem"
                whiteSpace="nowrap"
                _hover={{
                  textDecoration: 'none',
                  background: 'buttonPrimary'
                }}
                sx={{
                  background:
                    subItem?.url === routeMatch?.url
                      ? 'buttonPrimary'
                      : 'transparent'
                }}
                href={ensurePathStartByLocale({
                  path: subItem?.url ?? '',
                  locale
                })}
                onClick={() => {
                  if (subItem?.url?.includes('#')) {
                    setHash(subItem?.url?.split('#')[1]);
                  }
                }}
                isExternal={subItem?.target === '_blank'}
                lineHeight="normal"
              >
                {subItem.name}
              </Link>
            ))}
          </TabPanel>
        ))}
      </TabPanels>
    );
  };
  // onChangeTabs is called before onClickTab
  const onChangeTabs = (index: number) => {
    setOpenTabIndex(index);
    if (!hasSubMenu(index)) {
      setIsSubMenuOpen(false);
    }
  };
  // onClickTab is called after onChangeTabs
  const onClickTab = (e: MouseEvent, index: number) => {
    // Tab onClick is called after Tabs onChange
    if (hasSubMenu(index)) {
      if (index === openTabIndex) {
        if (previousOpenTabIndex === index) {
          setIsSubMenuOpen((b) => !b);
        } else {
          setIsSubMenuOpen(true);
        }
      }
    }
    setPreviousOpenTabIndex(index);
  };
  // need info for max height of sub menu
  useEffect(() => {
    if (tabPanelsRef.current && isSubMenuOpen) {
      const box = tabPanelsRef.current.getBoundingClientRect();
      tabPanelsRef.current.style.setProperty(
        '--tab-panels-top',
        `${box.top}px`
      );
    }
  }, [tabPanelsRef, isSubMenuOpen]);

  if (!showMenu) {
    return null;
  }

  return (
    <Tabs
      data-testid={'expandable-menu-tabs'}
      variant="enclosed"
      defaultIndex={openTabIndex}
      background={'backgroundPrimaryDarker'}
      borderRadius={[0, 0, isSubMenuOpen ? '15px 15px 0 0' : '15px']}
      onChange={onChangeTabs}
      {...tabProps}
    >
      <TabList
        px={7}
        mb={0}
        border="none"
        overflow="auto"
        css={ScrollWithoutScrollBar}
        data-testid={'expandable-menu-tabs-list'}
      >
        {items?.map((item, index) => (
          <Tab
            data-testid={'expandable-menu-tabs-list-tab'}
            key={index}
            py={0}
            px={3}
            border={0}
            position={'relative'}
            data-testhighlighted={(index === highlightedIndex).toString()}
            _after={
              index === highlightedIndex
                ? {
                    content: '""',
                    position: 'absolute',
                    background: 'buttonPrimary',
                    inset: 'auto 0 0',
                    height: 1
                  }
                : undefined
            }
            css={getTabOptionalStyles(item)}
            onClick={(e) => onClickTab(e, index)}
          >
            <Link
              data-testid={'expandable-menu-tabs-list-tab-link'}
              href={
                item?.children?.length
                  ? undefined
                  : ensurePathStartByLocale({ path: item?.url ?? '', locale })
              }
              _hover={{ textDecoration: 'none' }}
              isExternal={item?.target === '_blank'}
              display={'flex'}
              gap={1}
              alignItems={'center'}
              fontSize={'1.125rem'}
              lineHeight={'1.375rem'}
              fontWeight="500"
              color={index === highlightedIndex ? 'white' : 'whiteAlpha.600'}
              whiteSpace={'nowrap'}
              pt={4}
              pb={6}
              onClick={() => {
                if (item?.url?.includes('#')) {
                  setHash(item?.url?.split('#')[1]);
                }
              }}
            >
              {displayIcon(item, index)}
              {item.name}
              {displayArrow(item, index)}
            </Link>
          </Tab>
        ))}
      </TabList>
      {renderSubMenu()}
    </Tabs>
  );
}

const ensurePathStartByLocale = ({
  path,
  locale
}: {
  path?: string;
  locale: string;
}) =>
  path?.startsWith('/')
    ? path?.startsWith(`/${locale}`)
      ? path
      : `/${locale}${path}`
    : path;

export function getLinksFromItems<TItem extends Item>(items: TItem[]) {
  return items?.flatMap((item, index, array) => {
    if (item?.children?.length) {
      return item?.children?.map((subItem, childIndex) => ({
        index,
        url: subItem.url ?? '',
        item: subItem as Link | TItem
      }));
    } else {
      return [
        {
          index,
          url: item?.url ?? '',
          item: item as Link | TItem
        }
      ];
    }
  });
}

export type ExpendableMenuProps<T> = Omit<TabsProps, 'children'> & {
  initialHighlightedTabIndex?: number | undefined;
  initialOpenTabIndex?: number | undefined;
  items: T[];
  options?: Record<string, { icon?: ReactNode; styles?: SerializedStyles }>;
};

export interface Link {
  name: string;
  url: string | null;
  target: string | null;
}

export interface Item extends Link {
  order: number;
  optionName: string | null;
  children: Link[];
}
